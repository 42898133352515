// custom fontsources
import 'fontsource-montserrat';
import 'fontsource-merriweather';
import 'fontsource-nunito';
import 'fontsource-alegreya';


// General styles
import './src/styles/styles.scss';

export const onRouteUpdate = () => {
  if (document.querySelector('.instagram-media') !== null) {
    // Wait to ensure page is rendered first.
    setTimeout(function () {
      if (typeof gatsbyLoadInstagram !== 'undefined' && typeof window.gatsbyLoadInstagram === 'function') {
        window.gatsbyLoadInstagram();
        if (window.instgrm) {
          window.instgrm.Embeds.process();
        }
      }
    }, 0);
  }
};
export const onServiceWorkerUpdateReady = async (args) => {
  // TODO: Review with team if we want this funcionallity
  // const permissionResponse = await Notification.requestPermission();
  // if (permissionResponse === "granted") {
  //   await args.serviceWorker.showNotification("Website update", {
  //     body:
  //       "Our website just got a little bit better. We reloaded the site with the update to ensure a smooth experience for you."
  //   })
  // }
  window.location.reload(true);
}

// TODO:: More options for notifications when new version is released
// export const onServiceWorkerUpdateFound = () => {
//   const showNotification = () => {
//     Notification.requestPermission(result => {
//         if (result === 'granted') {
//             navigator.serviceWorker.ready.then(registration => {
//                 registration.showNotification('Update', {
//                     body: 'New content is available!',
//                     icon: 'link-to-your-icon',
//                     vibrate: [200, 100, 200, 100, 200, 100, 400],
//                     tag: 'request',
//                     actions: [ // you can customize these actions as you like
//                         {
//                             action: doSomething(), // you should define this
//                             title: 'update'
//                         },
//                         {
//                             action: doSomethingElse(), // you should define this
//                             title: 'ignore'
//                         }
//                     ]
//                 })
//             })
//         }
//     })
//   }

//   showNotification()
// }

export const onClientEntry = () => {
  if (window.location.hostname == 'proud-sea-0fea2f903.azurestaticapps.net' && window.location.pathname != '/blank.html') {
    location.href = '/blank.html';
  }
};
