// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aviso-legal-tsx": () => import("./../../../src/pages/aviso-legal.tsx" /* webpackChunkName: "component---src-pages-aviso-legal-tsx" */),
  "component---src-pages-chefs-tsx": () => import("./../../../src/pages/chefs.tsx" /* webpackChunkName: "component---src-pages-chefs-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-creditos-tsx": () => import("./../../../src/pages/creditos.tsx" /* webpackChunkName: "component---src-pages-creditos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-recetas-tsx": () => import("./../../../src/pages/recetas.tsx" /* webpackChunkName: "component---src-pages-recetas-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-basic-basic-tsx": () => import("./../../../src/templates/basic/basic.tsx" /* webpackChunkName: "component---src-templates-basic-basic-tsx" */),
  "component---src-templates-chef-detail-chef-detail-tsx": () => import("./../../../src/templates/chef-detail/chef-detail.tsx" /* webpackChunkName: "component---src-templates-chef-detail-chef-detail-tsx" */),
  "component---src-templates-recipe-detail-recipe-detail-tsx": () => import("./../../../src/templates/recipe-detail/recipe-detail.tsx" /* webpackChunkName: "component---src-templates-recipe-detail-recipe-detail-tsx" */)
}

